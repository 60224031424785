import React from 'react'
import styles from './dashboard.module.css'
import { useAuthDispatch, logout, useAuthState } from '../../Context';

function Dashboard(props) {

  const dispatch = useAuthDispatch();
  const userDetails = useAuthState();

  console.log(userDetails);

  const handleLogout = () => {
    logout(dispatch);
    props.history.push('/login');
  };

  return (
    <div style={{ padding: 10 }}>
      <div className={styles.dashboardPage} >
        <h1>
          Dashboard
        </h1>
        <p>Welcome to the dashboard, {userDetails.user.name}</p>
        <button className={styles.logoutBtn} onClick={handleLogout}>Logout</button>
      </div>
    </div>
  )
}

export default Dashboard
